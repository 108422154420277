import React, {useContext, useState} from "react";
import {
  Box,
  Checkbox, Divider, FormControl,
  FormControlLabel, IconButton, InputLabel, MenuItem, Select, Slider, Stack,
  TextField,
  Typography
} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {PersonaModal} from "../../../../../components/modals/PersonaModal";
import {WebSettingsContext} from "../../../../../context/WebSettingsContext";
import {IconArrowsDiagonal} from "@tabler/icons-react";
import {NodeType} from "../../../../../model/ModelData";

export const LLMBoostTab = ({node, onUpdate, onChangeData}) => {
  const [expandPersona, setExpandPersona] = useState(false);
  const [expandparaphrazerPersona, setExpandParaphrazerPersona] = useState(false);
  const {settings} = useContext(WebSettingsContext);

  const {primary, secondary} = menuItemsInfo.llm_boost;

  return (
    <>
      <PersonaModal
        handleClose={() => setExpandPersona(false)}
        open={expandPersona}
        onSuccess={(content) => onChangeData('system_prompt', content)}
        text={node?.data?.persona}
      />
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>

      {
        [NodeType.QA, NodeType.RECOMMENDATION].includes(node?.type) &&
        <>
          <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
            <FormControlLabel control={
              <Checkbox
                size={"small"}
                checked={node?.data?.paraphrazer}
                onChange={(e) => onChangeData('paraphrazer', e.target.checked)}
                onBlur={() => onUpdate(node)}
              />} label="Paraphrazer"/>
            <FormControlLabel control={
              <Checkbox
                size={"small"}
                checked={node?.data?.lemmatizer}
                onChange={(e) => onChangeData('lemmatizer', e.target.checked)}
                onBlur={() => onUpdate(node)}
              />} label="Lemmatizer"/>
          </Stack>

          <Box>
            <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pb: 0.5}}>
              <Typography fontWeight={700}>Persona for Paraphrazer</Typography>
              <Box>
                <IconButton disabled={!node?.data?.paraphrazer} onClick={() => setExpandParaphrazerPersona(true)}>
                  <IconArrowsDiagonal size={15}/>
                </IconButton>
              </Box>
            </Stack>
            <TextField
              value={node?.data?.persona_paraphrazer}
              size={"small"}
              fullWidth
              disabled={!node?.data?.paraphrazer}
              placeholder={"Describe persona"}
              multiline
              minRows={3}
              maxRows={9}
              // helperText={"Ask for confirmation"}
              onChange={(e) => onChangeData('persona_paraphrazer', e.target.value)}
              onBlur={() => onUpdate(node)}
            />
          </Box>
          <Divider/>
        </>
      }

      <Box>
        <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pb: 0.5}}>
          <Typography fontWeight={700}>System Prompt</Typography>
          <Box>
            <IconButton onClick={() => setExpandPersona(true)}>
              <IconArrowsDiagonal size={15}/>
            </IconButton>
          </Box>
        </Stack>
        <TextField
          value={node?.data?.system_prompt}
          size={"small"}
          fullWidth
          placeholder={"Describe persona"}
          multiline
          minRows={3}
          maxRows={9}
          // helperText={"Ask for confirmation"}
          onChange={(e) => onChangeData('system_prompt', e.target.value)}
          onBlur={() => onUpdate(node)}
        />
      </Box>
      <FormControl size={"small"}>
        <InputLabel id={`model-label`}
                    sx={{textTransform: "capitalize"}}>Model</InputLabel>
        <Select
          labelId={`model-label`}
          id={`model-select`}
          value={node?.data?.model || "none"}
          disabled={!node?.data?.use_llm}
          label={`Model`}
          onChange={(e => onChangeData("model", e.target.value === "none" ? null : e.target.value))}
          onBlur={() => onUpdate(node)}
        >
          {settings?.model?.map(model => {
            return (
              <MenuItem key={model} value={model}>{model}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <Box sx={{ px: 1 }}>
        <Typography fontWeight={600} fontSize={13}>Temperature</Typography>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Slider
            sx={{flex: 1}}
            value={node?.data?.temp || 0}
            min={0} max={1} step={0.05}
            onChange={e => onChangeData("temp", e.target.value)}
            onBlur={() => onUpdate(node)}
          />
          <Typography sx={{minWidth: 30}}>{node?.data?.temp?.toFixed(2) || 0}</Typography>
        </Stack>
      </Box>

      <TextField
        type={"number"}
        value={node?.data?.conversation_buffer_window}
        size={"small"}
        label={"Conversation buffer window"}
        placeholder={"Buffer window"}
        // helperText={"Ask for confirmation"}
        onChange={(e) => onChangeData('conversation_buffer_window', e.target.value)}
        onBlur={() => onUpdate(node)}
      />

      <FormControlLabel
        control={
          <Checkbox
            size={"small"}
            checked={node?.data?.share_conversation_context}
            onChange={(e) => onChangeData('share_conversation_context', e.target.checked)}
            onBlur={() => onUpdate(node)}
          />}
        label="Share Conversation Context"
      />

      <TextField
        type={"number"}
        value={node?.data?.contexts}
        size={"small"}
        // disabled={!node?.data?.share_conversation_context}
        InputLabelProps={{shrink: true}}
        label={"Number of Extracted Contexts"}
        placeholder={"Number of Extracted Contexts"}
        // helperText={"Ask for confirmation"}
        defaultValue={8}
        onChange={(e) => onChangeData('contexts', e.target.value)}
        onBlur={() => onUpdate(node)}
      />
    </>
  )
}