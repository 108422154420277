import React, {useEffect, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Autocomplete,
  Box, Button, Checkbox,
  FormControl, FormControlLabel, IconButton,
  InputLabel, MenuItem, Select,
  Stack, TextField, Tooltip,
  Typography
} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {NodeType} from "../../../../../model/ModelData";
import {v4 as uuidv4} from "uuid";
import {IconChevronDown, IconPlus, IconTrash} from "@tabler/icons-react";

export const DynamicRoutesTab = ({node, setNode, nodes, onUpdate, project, flow, selectedRoute}) => {
  const [intents, setIntents] = useState([]);

  useEffect(() => {
    if (project && flow) {
      const newIntents = project?.intents || [];
      setIntents(newIntents);
    }
  }, [project, flow]);

  const createRoute = () => {
    return {
      id: uuidv4(),
      target: null,
      name: null,
      type: node?.type === NodeType.SWITCH ? "expression" : "intent",
      backstack: false,
      data: {
        greet: "",
        value: ""
      }
    }
  }

  const addDynamicRoute = () => {
    const newNode = {...node};
    if (!newNode.routes.dynamic) {
      newNode.routes.dynamic = [];
    }
    newNode.routes.dynamic.push({
      type: node?.type === NodeType.SWITCH ? "expression" : "intent",
      target: null,
      id: uuidv4(),
      data: {
        intent_id: null,
        intent_name: null,
        greet: "",
        value: ""
      }
    })

    setNode(newNode);
  }

  const onDeleteRoute = async (routeId) => {
    const newNode = {...node};
    newNode.routes.dynamic = newNode.routes.dynamic?.filter(r => r.id !== routeId);
    setNode(newNode);
    await onUpdate(newNode);
  }

  let routes = node?.routes?.dynamic || [];

  let items = nodes?.filter(item => (item?.data?.form_id && item?.type === NodeType.START_FORM) || item)
  items = items?.map(node => ({...node, groupName: `Flow: ${flow?.name}`}));
  if (node?.data?.form_id) {
    const formNode = items?.find(item => item?.data?.form_id === node?.data?.form_id && item?.type === NodeType.START_FORM);
    items = items?.filter(item => item?.data?.form_id === node?.data?.form_id)?.map(item => ({...item, groupName: `Form: ${formNode?.name || flow?.name}`}));
  }
  if (node?.type === NodeType?.START) {
    project?.flows?.forEach(f => {
      const startNode = {...f?.nodes?.find(n => n?.type === NodeType.START)};
      if (startNode.id === node.id) return null;

      items?.push({...startNode, groupName: `Flow: ${f?.name}`})
    })
  }
  items = items.sort((a, b) => -b.groupName.localeCompare(a.groupName))

  const {primary, secondary} = menuItemsInfo.dynamic;

  return (
    <>
      <Box>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>
      {
        routes?.map((route, index) => {
          const selectedNode = items?.find(n => n?.id === route?.target);
          return (
            <Accordion
              elevation={0}
              defaultExpanded={route?.id === selectedRoute}
              sx={{
                maxWidth: "inherit",
                my: 0,
                pb: 0,
                border: "0 !important",
                "&.Mui-expanded": {my: "0 !important", border: 0},
                "&.MuiPaper-elevation": {border: 0}
              }}
            >
              <AccordionSummary
                expandIcon={<IconChevronDown size={15}/>}
                aria-controls={`${route?.id}-content`}
                id={`${route?.id}-header`}
                sx={{p: 0, border: 0}}
              >
                <Box>
                  <Typography fontWeight={"bold"} textTransform={"capitalize"}
                              fontSize={13}>{route?.data?.intent_name?.replaceAll("_", " ") || `Route ${index + 1}`}</Typography>
                  <Typography variant={"subtitle2"} fontSize={12}>This route will only be executed if intent is
                    catched</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{my: 0, p: 0}}>
                <Stack direction={"column"} gap={2}>
                  {
                    node?.type === NodeType.SWITCH ?
                      <TextField
                        label={"Expression"}
                        size={"small"}
                        fullWidth
                        value={route?.data?.value || null}
                        helperText={"Write here python expression"}
                        InputLabelProps={{shrink: true}}
                        onBlur={() => onUpdate(node)}
                        onChange={(e) => {
                          setNode(prev => {
                            const newNode = {...prev};
                            let next = {...newNode.routes.dynamic[index]};
                            if (!next) {
                              next = createRoute();
                            }
                            const data = {...next.data};
                            data.value = e.target.value;

                            next.id = next?.id || uuidv4();
                            next.data = data;
                            newNode.routes.dynamic[index] = next
                            return newNode;
                          })
                        }}
                      />
                      :
                      <FormControl size={"small"} fullWidth>
                        <InputLabel id={`${route?.id}-label`}
                                    sx={{textTransform: "capitalize"}}>Intent [{index}]</InputLabel>
                        <Select
                          labelId={`${route?.id}-label`}
                          id={`${route?.id}-select`}
                          value={route?.data?.intent_id || null}
                          label={`Intent [${index}]`}
                          onChange={(e) => {
                            setNode(prev => {
                              const newNode = {...prev};
                              const selectedIntent = intents?.find(i => i?.id === e.target.value);
                              if (selectedIntent) {
                                const find = newNode?.routes.dynamic[index];
                                let next = {...find};
                                if (!find) {
                                  next = createRoute();
                                }
                                next.id = next?.id || uuidv4();

                                const data = {
                                  ...next.data,
                                  intent_id: selectedIntent.id,
                                  intent_name: selectedIntent.name,
                                }
                                next.data = data;
                                newNode.routes.dynamic[index] = next;
                              }
                              return newNode;
                            })
                          }}
                          onBlur={() => onUpdate(node)}
                        >
                          <MenuItem value={null}><em>None</em></MenuItem>
                          {intents?.map(intent => <MenuItem key={intent?.id}
                                                            value={intent?.id}>{intent?.name || intent?.id}</MenuItem>)}
                        </Select>
                      </FormControl>
                  }
                  <Autocomplete
                    labelId={`${route?.id}-label`}
                    id={`${route?.id}-select`}
                    renderInput={(props) => <TextField {...props} size={"small"} label={`Dynamic route [${index}]`}/>}
                    options={items}
                    groupBy={option => option?.groupName}
                    getOptionLabel={option => option?.name}
                    value={selectedNode || null}
                    renderGroup={params => (
                      <li key={params.key}>
                        <Typography sx={{fontWeight: 700, p: 1, bgcolor: "rgba(0,0,0,0.015)"}}>{params.group} <IconChevronDown size={13} /></Typography>
                        <div style={{padding: 4, borderRadius: "4px !important"}}>
                          {params.children}
                        </div>
                      </li>
                    )}
                    blurOnSelect
                    onChange={(e, value) => {
                      const newNode = {...node}
                      let selectedNode = value;

                      if (selectedNode) {
                        const find = newNode?.routes?.dynamic[index];
                        let next = {...find};
                        if (!next) {
                          next = createRoute();
                        }
                        next.id = next?.id || uuidv4();
                        next.target = selectedNode.id;
                        next.name = selectedNode?.name;
                        newNode.routes.dynamic[index] = next;
                      } else {
                        newNode.routes.dynamic[index] = null;
                      }
                      onUpdate(newNode)
                    }}
                  />

                  <TextField
                    label={"Greet message"}
                    size={"small"}
                    fullWidth
                    value={node?.routes?.dynamic[index]?.data?.greet || null}
                    onChange={(e) => {
                      setNode(prev => {
                        const newNode = {...prev};
                        let next = {...newNode.routes?.dynamic[index]};
                        if (!next) {
                          next = createRoute();
                        }
                        const data = {...next.data};
                        data.greet = e.target.value;

                        next.id = next?.id || uuidv4();
                        next.data = data;
                        newNode.routes.dynamic[index] = next
                        return newNode;
                      })
                    }}
                    onBlur={() => onUpdate(node)}
                  />
                  <FormControlLabel control={
                    <Checkbox
                      size="small"
                      onChange={(e, value) => {
                        setNode(prev => {
                          const newNode = {...prev};
                          let next = {...newNode.routes?.dynamic[index]};
                          if (!next) {
                            next = createRoute();
                          }
                          next.id = next?.id || uuidv4();
                          next.backstack = value;
                          newNode.routes.dynamic[index] = next

                          return newNode;
                        })
                      }}
                      checked={node?.routes?.dynamic[index]?.backstack || false}
                    />} label={<Typography fontSize={13}>Backstack</Typography>}/>

                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography fontWeight={700} fontSize={13} sx={{flex: 1}}>Params</Typography>
                    <Tooltip title={"Add new param"}>
                      <IconButton
                        onClick={() => {
                          setNode(prev => {
                            const newNode = {...prev};
                            let next = {...newNode.routes.dynamic[index]};
                            if (!next) {
                              next = createRoute(route);
                            }
                            const data = {...next.data};
                            data.params = [...data?.params || [], ""];
                            next.id = next?.id || uuidv4();
                            next.data = data;
                            newNode.routes.dynamic[index] = next
                            return newNode;
                          })
                        }}
                      >
                        <IconPlus size={17}/>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  {
                    node?.routes?.dynamic[index]?.data?.params?.map((param, ind) => {
                      return <Stack
                        key={`${param}-${ind}`}
                        direction={"row"}
                        gap={1}
                      >
                        <TextField
                          label={`Param ${ind + 1}`}
                          size={"small"}
                          sx={{flex: 1}}
                          defaultValue={param}
                          onBlur={e => {
                            setNode(prev => {
                              const newNode = {...prev};
                              let next = {...newNode.routes.dynamic[index]};
                              if (!next) {
                                next = createRoute();
                              }
                              const data = {...next.data};
                              data.params[ind] = e.target.value

                              next.id = next?.id || uuidv4();
                              next.data = data;
                              newNode.routes.dynamic[index] = next
                              if (newNode?.routes?.dynamic[index] !== node?.routes?.dynamic[index]) {
                                onUpdate(newNode);
                              }
                              return newNode;
                            })
                          }}
                        />
                        <IconButton
                          color={"error"}
                          onClick={() => {
                            setNode(prev => {
                              const newNode = {...prev};
                              let next = {...newNode.routes.dynamic[index]};
                              if (!next) {
                                next = createRoute();
                              }
                              const data = {...next.data};
                              data.params = data?.params?.filter((p, i) => i !== index);

                              next.id = next?.id || uuidv4();
                              next.data = data;
                              newNode.routes.dynamic[index] = next
                              if (newNode?.routes?.dynamic[index] !== node?.routes?.dynamic[index]) {
                                onUpdate(newNode);
                              }
                              return newNode;
                            })
                          }}
                        >
                          <IconTrash size={15}/>
                        </IconButton>
                      </Stack>
                    })
                  }

                  <Button
                    variant={"outlined"}
                    color={"error"}
                    size={"small"}
                    fullWidth
                    sx={{textTransform: "none"}}
                    onClick={() => onDeleteRoute(route?.id)}
                  >
                    Delete Route
                  </Button>
                </Stack>

              </AccordionDetails>
            </Accordion>
          )
        })
      }
      <Button onClick={addDynamicRoute} fullWidth variant={"outlined"} size={"small"}
              sx={{textTransform: "none"}}>
        Create New Dynamic Route
      </Button>
    </>
  )
}
