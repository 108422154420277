import React, {useEffect, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, IconButton, Stack, TextField, Typography} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {IconTrash} from "@tabler/icons-react";
import {ChooseSlot} from "./ChooseSlot";
import {KBModal} from "../knowledge-base/KBModal";

export const SlotsTab = ({node, setNode, onUpdate, project, onChangeData}) => {
  const [selectSlot, setSelectSlot] = useState(null);
  const [createSlot, setCreateSlot] = useState(false);

  const onChangeDescription = async (index, value) => {
    const state = {...node}
    if (state?.data?.slots[index]?.desc !== value) {
      state.data.slots[index].desc = value;
      setNode(state)
    }
  }
  const onUpdateDescription = async () => {
    await onUpdate(node);
  }

  const removeDescription = async (index) => {
    const state = {...node}
    state.data.slots = state.data.slots?.filter((slot, ind) => ind !== index);
    await onUpdate(state);
  }

  const addSlots = async (newSlots) => {
    const state = {...node}
    if (state?.data?.slots?.length === 0) {
      state.data.slots = []
    }
    state.data.slots = [...state.data.slots, ...newSlots];
    setNode(state);
    await onUpdate(state);
  }

  const {primary, secondary} = menuItemsInfo.slots;

  return (
    project && <>
      <ChooseSlot
        open={createSlot}
        handleClose={() => setCreateSlot(false)}
        handleSuccess={addSlots}
        project={project}
      />
      <KBModal
        open={!!selectSlot}
        slot={selectSlot?.id}
        topic={selectSlot?.topic_id}
        organization={project?.settings?.organization}
        project={project}
        handleClose={() => setSelectSlot(null)}
        // url={`https://kb.openbrain.io/knowledge/${selectedOrganization}/${node?.data?.topic_id}/${node?.data?.slot_id}`}
      />
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>
      {
        node?.data?.slots?.map((slot, index) => {
          return <Box key={`${slot?.id}-${index}`} sx={{borderBottom: 1, borderColor: "divider", pb: 1}}>
            <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"space-between"}>
              <Button size={"small"} onClick={() => setSelectSlot(slot)} sx={{textDecoration: "underline"}}>
                <Typography fontWeight={700} sx={{pb: 0.5, fontSize: "0.8rem"}}>{index + 1}. {slot?.name}</Typography>
              </Button>

              <Box>
                <IconButton
                  color={"error"}
                  onClick={() => removeDescription(index)}
                >
                  <IconTrash size={13}/>
                </IconButton>
              </Box>
            </Stack>

            <TextField
              value={slot?.desc}
              size={"small"}
              fullWidth
              placeholder={"Write here message..."}
              multiline
              rows={3}
              sx={{flex: 1}}
              onChange={e => onChangeDescription(index, e.target.value)}
              onBlur={e => onUpdateDescription(index, e.target.value)}
            />
          </Box>
        })
      }

      <Button
        variant={"outlined"}
        color={"secondary"}
        onClick={() => setCreateSlot(true)}
      >
        Add new slot
      </Button>



      <FormControlLabel
        control={
          <Checkbox
            size={"small"}
            checked={node?.data?.mix_contexts}
            onChange={(e) => onChangeData('mix_contexts', e.target.checked)}
            onBlur={() => onUpdate(node)}
          />}
        label="Mix Contexts"
      />
    </>
  )
}