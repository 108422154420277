import React, {useEffect, useState} from "react";
import {Button, Fade, IconButton, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    IconX
} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {v4 as uuidv4} from "uuid";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "280px",
    maxHeight: "80vh",
    bgcolor: 'background.paper',
    borderRadius: 0,
    border: '0px solid #000',
    boxShadow: 24,
    p: 3,
    overflowY: "auto"
};

export const CreateConstModal = ({open, handleClose, selectedConstant, handleSubmit, project}) => {
    const [name, setName] = useState("");
    const [value, setValue] = useState("");

    useEffect(() => {
        if (selectedConstant && project) {
            setName(selectedConstant);
            setValue(project.constants[selectedConstant] || "");
        }
    }, [project, selectedConstant]);

    const onCreateConst = async () => {
        if (!name) {
            return;
        }
        const data = {...project};

        const constants = project?.constants ? {...project.constants} : {};
        constants[name] = value;

        data.constants = constants;
        handleSubmit(data);
        handleClose();
    }

    const onDelete = async () => {
        if (!selectedConstant) {
            return;
        }
        const data = {...project};
        const constants = data?.constants || {};
        const keys = Object.keys(constants)
        if (selectedConstant && keys.includes(selectedConstant)) {
            delete constants[selectedConstant];
        }
        data.constants = constants

        handleSubmit(data);
        handleClose();
    }

    const onClose = () => {
        setName("");
        setValue("");
        handleClose();
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open} timeout={500}>
                <Box>
                    <Box sx={{position: "fixed", top: 10, right: 10}}>
                        <IconButton
                            size={"small"}
                            onClick={onClose}
                            color={"#FFF"}
                        >
                            <IconX color={grey[300]}/>
                        </IconButton>
                    </Box>

                    <Stack direction={"column"} gap={2} sx={style}>
                        <Typography variant={"h4"} fontWeight={"bold"} sx={{mb: 1}}>{selectedConstant ? "Update" : "Add"} Constant</Typography>
                        <TextField
                            size={"small"}
                            label={"Name"}
                            value={name} autoFocus
                            onChange={e => setName(e?.target?.value?.toUpperCase()?.replaceAll(" ", "_"))}
                        />
                        <TextField
                            size={"small"}
                            label={"Value"}
                            value={value}
                            onChange={e => setValue(e?.target?.value)}
                        />

                        <Stack direction={'row'} gap={1} useFlexGap flexWrap={"wrap"}>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                sx={{borderRadius: 0, textTransform: "capitalize"}}
                                disabled={!name}
                                onClick={onCreateConst}
                            >
                                Save
                            </Button>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                sx={{borderRadius: 0, textTransform: "capitalize"}}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {
                                selectedConstant && <Button
                                variant={"outlined"}
                                color={"error"}
                                sx={{borderRadius: 0, textTransform: "capitalize", ml: "auto"}}
                                onClick={onDelete}
                            >
                                Delete
                            </Button>
                            }
                        </Stack>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    )
}
