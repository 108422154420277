import {
  Box,
  IconButton, InputAdornment,
  List, ListItem,
  ListItemButton,
  ListItemText,
  Stack, TextField,
  Fade,
  Typography, Button
} from "@mui/material";
import {grey} from "@mui/material/colors";
import React, {useContext, useState} from "react";
import {
  IconLoader,
  IconPlus,
  IconSearch, IconSettings,
  IconX
} from "@tabler/icons-react";
import {Context as AppContext} from "../../../../context/AppContext";
import {CreateIntentModal} from "./CreateIntentModal";
import {CreateEntityModal} from "./CreateEntityModal";
import {CreateFlowModal} from "./CreateFlow";
import {CreateConstModal} from "./CreateConstant";
import {CreateCollectionModal} from "./CreateCollection";

const selectedItemStyle = {
  borderRadius: 0, bgcolor: "transparent",
}

export const SideMenu = ({flows, selectedFlow, project, onSelectFlow}) => {
  const {updateProject, getUserProjects} = useContext(AppContext)

  return (
    <Fade in={!!project} timeout={500}>
      <Stack direction={"column"} gap={0.5} sx={{
        maxHeight: '100%',
        maxWidth: "220px",
        width: "100%",
        borderRight: 0,
        pb: 0.5,
        bgcolor: "transparent",
        borderColor: "divider",
        overflowY: "auto",
      }}>
        <FlowBlock
          project={project} flows={flows} getUserProjects={getUserProjects}
          onUpdateProject={updateProject} onSelectFlow={onSelectFlow} flow={selectedFlow}
        />

        <IntentBlock
          project={project} onUpdateProject={updateProject} flow={selectedFlow}
          getUserProjects={getUserProjects}
        />

        <EntityBlock
          project={project} onUpdateProject={updateProject} flow={selectedFlow}
          getUserProjects={getUserProjects}
        />

        <ConstsBlock
          project={project} onUpdateProject={updateProject} flow={selectedFlow}
          getUserProjects={getUserProjects}
        />

        <CollectionBlock
          project={project} onUpdateProject={updateProject} flow={selectedFlow}
          getUserProjects={getUserProjects}
        />
      </Stack>
    </Fade>
  )
}

const FlowBlock = ({project, flows, onUpdateProject, getUserProjects, flow, onSelectFlow}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [search, setSearch] = useState("");

  const onUpdateFlow = async (newProject) => {
    await onUpdateProject(newProject);
    await getUserProjects();
  }

  return (
    <>
      <CreateFlowModal
        project={project}
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          setSelectedFlow(null);
        }}
        flow={selectedFlow}
        handleSubmit={onUpdateFlow}
      />
      <ListBlock
        isOpen
        title={"Flows"}
        items={project?.flows?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase()))}
        search={search}
        onChangeSearch={(val) => setSearch(val)}
        onCreate={() => setOpenModal(true)}
        mapItem={(item) => {
          const isSelected = item?.id === flow?.id;
          let style = {
            border: 1,
            borderColor: "transparent",
            m: 0.5,
            p: 0.5,
            bgcolor: "transparent"
          };
          if (isSelected) {
            style = {...style, ...selectedItemStyle};
          }
          return (
            <ListItem
              key={item?.id || item?.key}
              className={`side-link ${isSelected ? 'active' : ''}`}
              sx={{
                p: 0,
                m: 0,
                color: "dark.main",
                // bgcolor: isSelected ? "rgba(220,228,234, 0.5)" : "transparent",
                // "&:hover": {borderRadius: 0, bgcolor: "rgba(220,228,234, 0.5)"},
                "&:hover > *": {opacity: 1, bgcolor: "transparent !important"}
              }}
            >
              <ListItemButton
                sx={style}
                onClick={() => onSelectFlow(item)}
              >
                <Typography noWrap sx={{
                  fontSize: 12,
                  color: "dark.main",
                  fontWeight: isSelected ? "bold" : "normal"
                }}>{item?.name}</Typography>
              </ListItemButton>
              <Box className={"create-btn"} sx={{mr: 0.5, opacity: 0}}>
                <IconButton
                  size={"small"}
                  sx={{color: "dark.main"}}
                  onClick={() => {
                    setSelectedFlow(item);
                    setOpenModal(true);
                  }}
                >
                  <IconSettings size={14}/>
                </IconButton>
              </Box>
            </ListItem>
          )
        }}
      />
    </>
  )
}
const IntentBlock = ({project, onUpdateProject, getUserProjects, flow}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState(null);
  const [search, setSearch] = useState("");

  const onUpdateIntent = async (intent) => {
    const newProject = {...project};
    const index = newProject?.intents?.findIndex(i => i?.id === intent?.id);
    if (isFinite(index) && index >= 0) {
      newProject.intents[index] = intent;
    } else {
      newProject.intents.push(intent);
    }
    await onUpdateProject(newProject);
    await getUserProjects();
  }

  const onRemove = async (id) => {
    const newProject = {...project};
    newProject.intents = newProject?.intents?.filter(item => item.id !== id);
    await onUpdateProject(newProject);
    await getUserProjects();
  }

  return (
    <>
      <CreateIntentModal
        flow={flow}
        open={openModal}
        project={project}
        handleClose={() => {
          setOpenModal(false);
          setSelectedIntent(null);
        }}
        intent={selectedIntent}
        handleSubmit={onUpdateIntent}
        onRemove={onRemove}
      />
      <ListBlock
        title={"Intents"}
        items={project?.intents?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())) || []}
        onCreate={() => setOpenModal(true)}
        search={search}
        onChangeSearch={(val) => setSearch(val)}
        mapItem={(intent) => {
          let style = {
            border: 1,
            borderColor: "transparent",
            m: 0.5,
            p: 0.5,
            "&:hover": {borderRadius: 0, bgcolor: "rgba(220,228,234, 0.5)"},
          };

          return (
            <ListItemButton
              key={intent?.id || intent?.key}
              sx={style}
              className={`side-link`}
              onClick={() => {
                setSelectedIntent(intent);
                setOpenModal(true);
              }}>
              <Typography noWrap sx={{fontSize: 13}}>{intent?.name}</Typography>
            </ListItemButton>
          )
        }}
      />
    </>
  )
}
const EntityBlock = ({project, onUpdateProject, getUserProjects, flow}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const [search, setSearch] = useState("");

  const onUpdateEntity = async (entity) => {
    const newProject = {...project};
    const index = newProject?.entities?.findIndex(i => i?.id === entity?.id);
    if (isFinite(index) && index >= 0) {
      newProject.entities[index] = entity;
    } else {
      newProject.entities.push(entity);
    }
    await onUpdateProject(newProject);
    await getUserProjects();
  }


  const onRemove = async (id) => {
    const newProject = {...project};
    newProject.entities = newProject?.entities?.filter(item => item.id !== id);
    await onUpdateProject(newProject);
    await getUserProjects();
  }

  return (
    <>
      <CreateEntityModal
        flow={flow}
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          setSelectedEntity(null);
        }}
        entity={selectedEntity}
        handleSubmit={onUpdateEntity}
        onRemove={onRemove}
      />
      <ListBlock
        title={"Entities"}
        items={project?.entities?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())) || []}
        search={search}
        onChangeSearch={(val) => setSearch(val)}
        onCreate={() => setOpenModal(true)}
        mapItem={(entity) => {
          let style = {
            border: 1,
            borderColor: "transparent",
            m: 0.5,
            p: 0.5,
            "&:hover": {borderRadius: 0, bgcolor: "rgba(220,228,234, 0.5)"}
          };

          return (
            <ListItemButton
              key={entity?.id || entity?.key}
              sx={style}
              className={`side-link`}
              onClick={() => {
                setSelectedEntity(entity);
                setOpenModal(true);
              }}>
              {/*<ListItemIcon sx={{minWidth: 25}}/>*/}
              <ListItemText><Typography noWrap
                                        sx={{fontSize: 13}}>{entity?.name}</Typography></ListItemText>
            </ListItemButton>
          )
        }}
      />
    </>
  )
}

const ConstsBlock = ({project, onUpdateProject, getUserProjects, flow}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedConstant, setSelectedConstant] = useState(null);

  const [search, setSearch] = useState("");

  const onUpdateConstants = async (data) => {
    const newProject = {...data};
    await onUpdateProject(newProject);
    await getUserProjects();
  }


  const onRemove = async (id) => {
    const newProject = {...project};
    newProject.constants = newProject?.constants?.filter(item => item.id !== id);
    await onUpdateProject(newProject);
    await getUserProjects();
  }

  return (
    <>
      <CreateConstModal
        open={openModal}
        project={project}
        selectedConstant={selectedConstant}
        handleClose={() => {
          setOpenModal(false);
          setSelectedConstant(null);
        }}
        handleSubmit={onUpdateConstants}
        onRemove={onRemove}
      />
      <ListBlock
        title={"Constants"}
        items={Object?.entries(project?.constants || {})?.map(([key, value]) => ({key, value}))?.filter((item) => item.key?.toLowerCase()?.includes(search?.toLowerCase())) || []}
        search={search}
        onChangeSearch={(val) => setSearch(val)}
        onCreate={() => setOpenModal(true)}
        mapItem={(constant) => {
          let style = {
            border: 1,
            borderColor: "transparent",
            m: 0.5,
            p: 0.5,
            "&:hover": {borderRadius: 0, bgcolor: "rgba(220,228,234, 0.5)"}
          };

          return (
            <ListItemButton
              key={constant?.key}
              sx={style}
              className={`side-link`}
              onClick={() => {
                setSelectedConstant(constant.key);
                setOpenModal(true);
              }}>
              {/*<ListItemIcon sx={{minWidth: 25}}/>*/}
              <ListItemText><Typography noWrap
                                        sx={{fontSize: 13}}>{constant?.key} - {constant?.value}</Typography></ListItemText>
            </ListItemButton>
          )
        }}
      />
    </>
  )
}

const CollectionBlock = ({project, onUpdateProject, getUserProjects, flow}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const [search, setSearch] = useState("");

  const onUpdateCollections = async (data) => {
    const newProject = {...data};
    await onUpdateProject(newProject);
    await getUserProjects();
  }


  const onRemove = async (id) => {
    const newProject = {...project};
    newProject.collections = newProject?.collections?.filter(item => item.id !== id);
    await onUpdateProject(newProject);
    await getUserProjects();
  }

  return (
    <>
      <CreateCollectionModal
        open={openModal}
        project={project}
        selectedCollection={selectedCollection}
        handleClose={() => {
          setOpenModal(false);
          setSelectedCollection(null);
        }}
        handleSubmit={onUpdateCollections}
        onRemove={onRemove}
      />
      <ListBlock
        title={"Collections"}
        items={project?.collections?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())) || []}
        search={search}
        onChangeSearch={(val) => setSearch(val)}
        onCreate={() => setOpenModal(true)}
        mapItem={(collection) => {
          let style = {
            border: 1,
            borderColor: "transparent",
            m: 0.5,
            p: 0.5,
            "&:hover": {borderRadius: 0, bgcolor: "rgba(220,228,234, 0.5)"}
          };

          return (
            <ListItemButton
              key={collection?.id}
              sx={style}
              className={`side-link`}
              onClick={() => {
                setSelectedCollection(collection);
                setOpenModal(true);
              }}>
              {/*<ListItemIcon sx={{minWidth: 25}}/>*/}
              <ListItemText><Typography noWrap
                                        sx={{fontSize: 13}}>{collection?.name}</Typography></ListItemText>
            </ListItemButton>
          )
        }}
      />
    </>
  )
}

export const ListBlock = ({items, title, mapItem, search, onChangeSearch, onCreate, isOpen}) => {
  const [open, setOpen] = useState(!!isOpen);
  const [pagination, setPagination] = useState(5);

  return (
    <Box
      className={"side-item"}
      sx={{
        // border: open ? 1 : 0,
        borderBottom: 1,
        borderColor: "rgba(220,228,234, 0.5)",
        // bgcolor: open ? "rgba(220,228,234, 0.3)" : "#FFF",
        p: 0.5,
        m: 0.5,
        mb: 0,
        borderRadius: open ? 0 : 0
      }}
    >
      <List sx={{py: 0, m: 0}}>
        <ListItem
          sx={{
            mb: open ? 0.5 : 0,
            p: 0, zIndex: 10,
            // "&:hover": {bgcolor: open ? "rgba(220,228,234, 0.5)" : "rgba(220,228,234, 0.3)"},
            "&:hover > .create-btn": {opacity: 1, bgcolor: "transparent"},
            "&:hover": {opacity: 1, bgcolor: "transparent"}
          }}
        >
          <ListItemButton
            sx={{p: 1, "&:hover": {background: "transparent !important"}}}
            onClick={() => setOpen(!open)}>
            <Typography sx={{color: "dark.main", fontWeight: "bold", fontSize: 12}}>{title}</Typography>
          </ListItemButton>
          <IconButton className={"create-btn"} size={"small"} onClick={onCreate}
                      sx={{zIndex: 100, opacity: open ? 1 : 0, color: "dark.main"}}>
            <IconPlus size={13}/>
          </IconButton>
        </ListItem>
        {
          open &&
          <Box sx={{px: 1}}>
            <TextField
              size={"small"}
              variant={"standard"}
              value={search}
              fullWidth
              placeholder={"Search"}
              onChange={(e) => onChangeSearch(e.target.value)}
              InputProps={{
                sx: {borderRadius: 0, mb: 1},
                startAdornment: <InputAdornment position="start">
                  <IconSearch size={14}/>
                </InputAdornment>,
                endAdornment: search ? <InputAdornment position="end">
                  <IconButton size={"small"} onClick={() => onChangeSearch("")}>
                    <IconX size={14}/>
                  </IconButton>
                </InputAdornment> : null
              }}
            />
          </Box>
        }
        {
          open && items?.slice(0, pagination)?.map(item => mapItem(item))
        }
      </List>
      {
        open && items?.length > 5 ?
          <>
            {
              items?.length > pagination ?
                <Button fullWidth onClick={() => setPagination(pagination + 5)}
                        sx={{fontSize: 12, textTransform: "none", color: grey[700]}}><IconLoader size={14}
                                                                                                 style={{marginRight: 5}}/> Show
                  More</Button>
                : <Button fullWidth onClick={() => setPagination(5)}
                          sx={{fontSize: 12, textTransform: "none", color: grey[700]}}><IconLoader size={14}
                                                                                                   style={{marginRight: 5}}/> Show
                  Less</Button>

            }
          </> : null
      }
    </Box>
  )
}