import {Input, Stack, Typography} from "@mui/material";
import React from "react";


export const SqlSelectInput = ({value, onChange}) => {

  return (
    <Stack direction={"row"} gap={1} alignItems={"flex-end"}>
      <Typography sx={{flex: 1}} fontWeight={"bold"} className={"sql-color"}>SELECT</Typography>
      <Input
        placeholder={"*"}
        sx={{flex: 3}}
        name={"select"}
        value={value}
        onBlur={onChange}
      />
    </Stack>
  )
}