import React, {useEffect, useMemo, useState} from "react";
import {Button, Divider, Fade, IconButton, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {IconPlus, IconTrash, IconX} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {v4 as uuid} from "uuid"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "320px",
    maxWidth: "450px", width: "90%",
    maxHeight: "80vh",
    bgcolor: 'background.paper',
    borderRadius: 0,
    border: '0px solid #000',
    boxShadow: 24,
    p: 3,
    overflowY: "auto"
};

export const CreateCollectionModal = ({open, handleClose, selectedCollection, handleSubmit, project}) => {
    const [name, setName] = useState("");
    const [fields, setFields] = useState([]);

    useEffect(() => {
        if (selectedCollection && project) {
            setName(selectedCollection?.name || "");
            setFields(selectedCollection?.fields || []);
        }
    }, [project, selectedCollection]);

    const onCreateConst = async () => {
        if (!name) {
            return;
        }
        const data = {...project};
        const newCollection = selectedCollection ? {...selectedCollection} : {id: uuid()};
        newCollection.name = name;
        newCollection.fields = fields;
        if (!data?.collections) {
            data.collections = [];
        }
        data.collections.push(newCollection);

        handleSubmit(data);
        handleClose();
    }

    const onDelete = async () => {
        if (!selectedCollection) {
            return;
        }
        const data = {...project};
        data.collections = data?.collections?.filter(i => i?.id === selectedCollection?.id) || []

        handleSubmit(data);
        handleClose();
    }

    const onClose = () => {
        setName("");
        setFields([]);
        handleClose();
    }

    const checkFieldIsValid = (name) => {
        if (name?.length === 0) {
            return "Field cannot be empty.";
        }
        const fieldNames = fields?.filter(i => i?.name === name)?.length || 0;
        return fieldNames <= 1 ? null : "Field name must be unique.";
    }

    const collectionNameIsValid = useMemo(() => {
        return (project?.collections?.filter(i => i?.name === name)?.length || 0) <= 1;
    }, [name, project?.collections]);

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open} timeout={500}>
                <Box>
                    <Box sx={{position: "fixed", top: 10, right: 10}}>
                        <IconButton
                            size={"small"}
                            onClick={onClose}
                            color={"#FFF"}
                        >
                            <IconX color={grey[300]}/>
                        </IconButton>
                    </Box>

                    <Stack direction={"column"} gap={2} sx={style}>
                        <Typography variant={"h4"} fontWeight={"bold"} sx={{mb: 1}}>{selectedCollection ? "Update" : "Create"} Collection</Typography>
                        <Box>

                            <Typography fontWeight={600} gutterBottom>Name:</Typography>
                            <TextField
                              size={"small"} placeholder={"Enter name of the collection"}
                              helperText={"Name should be unique"}
                              error={!collectionNameIsValid}
                              value={name} autoFocus fullWidth
                              onChange={e => setName(e?.target?.value?.replaceAll(" ", "_"))}
                            />
                        </Box>
                        <Box>
                            <Stack direction={"row"} fullWidth justifyContent={"space-between"} alignItems={"center"}>
                                <Typography fontWeight={600}>Fields:</Typography>
                                <IconButton onClick={() => setFields(prev => ([...prev, {id: uuid(), name: ""}]))}><IconPlus/></IconButton>
                            </Stack>
                            <Stack direction={"column"} gap={1} sx={{pl: 0, borderLeft: 1, borderColor: "divider"}}>
                                {fields?.map((field, index) => {
                                    const error = checkFieldIsValid(field?.name);
                                    return (
                                      <Stack key={field?.id} direction={"row"} alignItems={"flex-start"} gap={1}>
                                          <Divider sx={{width: "12px", pt: "16px"}} />
                                          <TextField
                                            placeholder={"Field name"}
                                            helperText={error || "Unique name"}
                                            size={"small"}
                                            value={field?.name}
                                            error={!!error}
                                            onChange={(e) => setFields(prev => {
                                                const state = [...prev];
                                                state[index].name = e.target.value?.replaceAll(" ", "_");
                                                return state;
                                            })}
                                          />
                                          <Box>
                                              <IconButton color={"error"} onClick={() => setFields(prev => (prev?.filter(i => i?.id !== field?.id)))}><IconTrash/></IconButton>
                                          </Box>
                                      </Stack>
                                    )
                                })}
                            </Stack>
                        </Box>

                        <Stack direction={'row'} gap={1} useFlexGap flexWrap={"wrap"}>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                sx={{borderRadius: 0, textTransform: "capitalize"}}
                                disabled={!name}
                                onClick={onCreateConst}
                            >
                                Save
                            </Button>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                sx={{borderRadius: 0, textTransform: "capitalize"}}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {
                                selectedCollection && <Button
                                variant={"outlined"}
                                color={"error"}
                                sx={{borderRadius: 0, textTransform: "capitalize", ml: "auto"}}
                                onClick={onDelete}
                            >
                                Delete
                            </Button>
                            }
                        </Stack>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    )
}
