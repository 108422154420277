import React from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import {ScopeType, TargetType} from "../../../../../model/ModelData";
import {IconPlus, IconTrash} from "@tabler/icons-react";
import {v4 as uuid} from "uuid"

const BodyParamsType = {
  JSON: "application/json",
  URL_ENCODED: "application/x-www-form-urlencoded"
}

export const HookSettings = ({node, setNode, onUpdate, project, onChangeData}) => {

  const onAddScope = () => {
    setNode(prev => {
      const state = {...prev}
      if (!state.data.params) {
        state.data.params = [];
      }
      state.data.params.push({id: uuid(), source: "", scope: ScopeType.FLOW})
      return state;
    })
  }
  const onAddQueryParams = () => {
    setNode(prev => {
      const state = {...prev}
      if (!state.data.queryParams) {
        state.data.queryParams = [];
      }
      state.data.queryParams.push({id: uuid(), key: "", value: ""})
      return state;
    })
  }
  const onAddBodyParams = () => {
    setNode(prev => {
      const state = {...prev}
      if (!state.data.bodyParams) {
        state.data.bodyParams = [];
      }
      state.data.bodyParams.push({id: uuid(), key: "", value: ""})
      return state;
    })
  }

  return (
    <>
      <Typography fontWeight={700} fontSize={13}>Hook API</Typography>
      <Stack direction={"row"} gap={1}>
        <FormControl size={"small"}>
          <InputLabel id={`api-method-label`}
                      sx={{textTransform: "capitalize"}}>Api Method</InputLabel>
          <Select
            labelId={`api-method-label`}
            id={`api-method-select`}
            value={node?.data?.api_method || "none"}
            label={`Api Method`}
            onChange={(e) => {
              setNode(prev => {
                return {
                  ...prev,
                  data: {
                    ...prev.data,
                    api_method: e.target.value === "none" ? null : e.target.value
                  }
                }
              })
            }}
            onBlur={() => onUpdate(node)}
          >
            <MenuItem value={"none"}><em>None</em></MenuItem>
            <MenuItem value={"GET"}>GET</MenuItem>
            <MenuItem value={"POST"}>POST</MenuItem>
            <MenuItem value={"PUT"}>PUT</MenuItem>
            <MenuItem value={"PATCH"}>PATCH</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={"Api URL"}
          size={"small"}
          value={node?.data?.api_url}
          InputLabelProps={{shrink: true}}
          sx={{flex: 1}}
          onChange={(e) => {
            setNode(prev => {
              return {
                ...prev,
                data: {
                  ...prev.data,
                  api_url: e.target.value
                }
              }
            })
          }}
          onBlur={() => onUpdate(node)}
        />
      </Stack>
      <Stack direction={"column"} gap={1}>
        <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
          <Typography fontWeight={700} fontSize={13}>Query Params</Typography>
          <IconButton onClick={onAddQueryParams} size={"small"}>
            <IconPlus size={18} />
          </IconButton>
        </Stack>
        {
          node?.data?.queryParams?.map((queryParam, index) => {
            return (
              <Stack key={queryParam?.id} direction={"row"} gap={1} alignItems={"center"}>
                <TextField
                  size={"small"}
                  value={queryParam?.key}
                  placeholder={"Key"}
                  onChange={e => {
                    setNode(prev => {
                      const state = {...prev}
                      state.data.queryParams[index].key = e.target.value;
                      return state;
                    })
                  }}
                />
                <TextField
                  size={"small"}
                  value={queryParam?.value}
                  placeholder={"Value"}
                  onChange={e => {
                    setNode(prev => {
                      const state = {...prev}
                      state.data.queryParams[index].value = e.target.value;
                      return state;
                    })
                  }}
                />
                <IconButton
                  color={"error"}
                  onClick={() => {
                    setNode(prev => {
                      const state = {...prev}
                      if (!state.data.queryParams) {
                        state.data.queryParams = [];
                      }
                      state.data.queryParams = state.data.queryParams.filter((i, ind) => i.id !== queryParam?.id || ind !== index);
                      return state;
                    })
                  }}
                >
                  <IconTrash size={15} />
                </IconButton>
              </Stack>
            )
          })
        }
      </Stack>
      <Stack direction={"column"} gap={1} sx={{display: node?.data?.api_method === "POST" || node?.data?.api_method === "PUT" ? "flex" : "none"}}>
        <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
          <Typography fontWeight={700} fontSize={13}>Body</Typography>
        </Stack>
        <Stack direction={"row"} gap={1}>
          <Button
            color={node?.data?.body_params_type === BodyParamsType.JSON ? "primary" : "secondary"}
            onClick={() => setNode(prev => ({...prev, data: {...prev.data, body_params_type: BodyParamsType.JSON}}))}
          >
            application/json
          </Button>
          <Button
            color={node?.data?.body_params_type === BodyParamsType.URL_ENCODED ? "primary" : "secondary"}
            onClick={() => setNode(prev => ({...prev, data: {...prev.data, body_params_type: BodyParamsType.URL_ENCODED}}))}
          >
            x-form-urlencoded
          </Button>
        </Stack>
        {
          node?.data?.body_params_type === BodyParamsType.URL_ENCODED ?
            <TextField
              label={"x-form-urlencoded"}
              size={"small"}
              value={node?.data?.body}
              InputLabelProps={{shrink: true}}
              sx={{flex: 1}}
              multiline rows={7}
              onChange={(e) => {
                setNode(prev => {
                  return {
                    ...prev,
                    data: {
                      ...prev.data,
                      body: e.target.value
                    }
                  }
                })
              }}
              onBlur={() => onUpdate(node)}
            />
            :
            <>
              {node?.data?.bodyParams?.map((bodyParam, index) => {
                return (
                  <Stack key={bodyParam?.id} direction={"row"} gap={1} alignItems={"center"}>
                    <TextField
                      size={"small"}
                      value={bodyParam?.key}
                      placeholder={"Key"}
                      onChange={e => {
                        setNode(prev => {
                          const state = {...prev}
                          state.data.bodyParams[index].key = e.target.value;
                          return state;
                        })
                      }}
                    />
                    <TextField
                      size={"small"}
                      value={bodyParam?.value}
                      placeholder={"Value"}
                      onChange={e => {
                        setNode(prev => {
                          const state = {...prev}
                          state.data.bodyParams[index].value = e.target.value;
                          return state;
                        })
                      }}
                    />
                    <IconButton
                      color={"error"}
                      onClick={() => {
                        setNode(prev => {
                          const state = {...prev}
                          if (!state.data.bodyParams) {
                            state.data.bodyParams = [];
                          }
                          state.data.bodyParams = state.data.bodyParams.filter((i, ind) => i.id !== bodyParam?.id || ind !== index);
                          return state;
                        })
                      }}
                    >
                      <IconTrash size={15} />
                    </IconButton>
                  </Stack>
                )
              })}
              <Button onClick={onAddBodyParams} size={"small"} startIcon={<IconPlus size={18} />}>
                Add Body Param
              </Button>
            </>
        }
      </Stack>
      <Typography fontWeight={700} fontSize={13}>Target</Typography>
      <Select
        size={"small"}
        labelId={`target-method-label`}
        id={`target-method-select`}
        value={node?.data?.target || null}
        label={`Target`}
        onBlur={() => onUpdate(node)}
        onChange={(e) => {
          setNode(prev => {
            return {
              ...prev,
              data: {
                ...prev.data,
                target: e.target.value
              }
            }
          })
        }}
      >
        <MenuItem value={null}><em>None</em></MenuItem>
        <MenuItem value={TargetType.FORM}>Form</MenuItem>
        <MenuItem value={TargetType.FLOW}>Flow</MenuItem>
        <MenuItem value={TargetType.GLOBAL}>Global</MenuItem>
      </Select>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography fontWeight={700} fontSize={13} sx={{flex: 1}}>Scope</Typography>
        <Tooltip title={"Add scope"}>
          <IconButton onClick={onAddScope} size={"small"}>
            <IconPlus size={18} />
          </IconButton>
        </Tooltip>
      </Stack>
      {
        node?.data?.params?.map((param, index) => {
          return (
            <Stack key={param?.id || index} direction={"row"} gap={1}>

              <FormControl size={"small"}>
                <InputLabel id={`scope-method-label`}
                            sx={{textTransform: "capitalize"}}>Scope</InputLabel>
                <Select
                  labelId={`scope-method-label`}
                  id={`scope-method-select`}
                  value={node?.data.params[index].scope || null}
                  onBlur={() => onUpdate(node)}
                  label={`Scope`}
                  onChange={(e) => {
                    setNode(prev => {
                      const state = {...prev}
                      state.data.params[index].scope = e.target.value;
                      return state;
                    })
                  }}
                >
                  <MenuItem value={null}><em>None</em></MenuItem>
                  <MenuItem value={ScopeType.FORM}>Form</MenuItem>
                  <MenuItem value={ScopeType.FLOW}>Flow</MenuItem>
                  <MenuItem value={ScopeType.GLOBAL}>Global</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={"Source"}
                size={"small"}
                value={node.data.params[index].source}
                sx={{flex: 1}}
                InputLabelProps={{shrink: true}}
                onBlur={() => onUpdate(node)}
                onChange={(e) => {
                  setNode(prev => {
                    const state = {...prev}
                    if (!state.data.params) {
                      state.data.params = [];
                    }
                    state.data.params[index].source = e.target.value;
                    return state;
                  })
                }}
              />
              <IconButton
                color={"error"}
                // size={"small"}
                onClick={() => {
                  setNode(prev => {
                    const state = {...prev}
                    if (!state.data.params) {
                      state.data.params = [];
                    }
                    console.log(state.data.params, index)
                    state.data.params = state.data.params.filter((i, ind) => i.id !== param?.id || ind !== index);
                    console.log(state.data.params, index)
                    return state;
                  })
                }}
              >
                <IconTrash size={15} />
              </IconButton>
            </Stack>
          )
        })
      }
      {/*<Button*/}
      {/*  variant={"outlined"}*/}
      {/*  onClick={onAddScope}*/}
      {/*>*/}
      {/*  Add Scope Param*/}
      {/*</Button>*/}
    </>
  )
}