import React from "react";
import {NodeType} from "../../../../../model/ModelData";
import {KnowledgeBaseSettings} from "../settings/KnowledgeBaseSettings";
import {Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";

export const KnowledgeBaseTab = ({node, setNode, nodes, onUpdate, project}) => {
  const {primary, secondary} = menuItemsInfo.knowledge_base;

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>

      <TextField
        label={"QA Api"}
        size={"small"}
        value={node?.data?.qa_api}
        InputLabelProps={{shrink: true}}
        onBlur={() => onUpdate(node)}
        onChange={(e) => {
          setNode(prev => {
            return {
              ...prev,
              data: {
                ...prev.data,
                qa_api: e.target.value
              }
            }
          })
        }}
      />
      <FormControl size={"small"} fullWidth>
        <InputLabel id={`qa-method-label`}
                    sx={{textTransform: "capitalize"}}>QA Method</InputLabel>
        <Select
          labelId={`qa-method-label`}
          id={`qa-method-select`}
          value={node?.data?.qa_method || null}
          label={`QA Method`}
          onBlur={() => onUpdate(node)}
          onChange={(e) => {
            setNode(prev => {
              return {
                ...prev,
                data: {
                  ...prev.data,
                  qa_method: e.target.value
                }
              }
            })
          }}
        >
          <MenuItem value={null}><em>None</em></MenuItem>
          <MenuItem value={"GET"}>GET</MenuItem>
          <MenuItem value={"POST"}>POST</MenuItem>
          <MenuItem value={"PUT"}>PUT</MenuItem>
          <MenuItem value={"PATCH"}>PATCH</MenuItem>
        </Select>
      </FormControl>

      {
        node?.type !== NodeType.QA &&
        <KnowledgeBaseSettings node={node} setNode={setNode} onUpdate={onUpdate} project={project}/>
      }
    </>
  )
}