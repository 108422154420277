import {menuItemsInfo} from "../editMenuItems";
import React, {useEffect, useState} from "react";
import {Box, IconButton, Input, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {IconPencil} from "@tabler/icons-react";
import {SqlSelectInput} from "./SqlSelectInput";
import {SqlFromInput} from "./SqlFromInput";
import {SqlFilterModal} from "./SqlFilterModal";


export const FilterTab = ({node, setNode, nodes, onUpdate, project}) => {
  const {primary, secondary} = menuItemsInfo.filter;
  const [edit, setEdit] = useState(false);

  const onChange = (e) => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    if (!key) {
      return;
    }
    const newNode = {...node};
    if (newNode?.data?.filter) {
      newNode.data.filter = {
        select: "*",
        from: "",
        where: "",
        query: [],
        collection: null
      }
    }
    newNode.data.filter[key] = value;
    onUpdate(newNode)
  }

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>
      <SqlFilterModal node={node} project={project} handleClose={() => setEdit(false)} open={edit} onUpdate={onUpdate} />
      <Stack direction={"column"} gap={2}
             sx={{position: "relative", borderRadius: 1, border: 1, borderColor: "divider", bgcolor: grey[50], p: 1, pb: 2}}>
        <IconButton
          size={"small"}
          sx={{position: "absolute", p: 0.5, top: 0, right: 15, transform: "translate(50%, -50%)"}}
          onClick={() => setEdit(true)}
        >
          <IconPencil size={15}/>
        </IconButton>

        <Typography variant={"h5"} color={grey[500]}>Collection: <b>{node?.data?.filter?.collection?.name}</b></Typography>
        <SqlSelectInput value={node?.data?.filter?.select} onChange={onChange} />
        <SqlFromInput value={node?.data?.filter?.from} onChange={onChange} />
        <Stack direction={"column"} gap={1}>
          <Typography fontWeight={"bold"} className={"sql-color"}>WHERE</Typography>
          <Typography variant={"subtitle2"} color={grey[500]} noWrap sx={{textOverflow: "ellipsis"}}>
            {node?.data?.filter?.where ? <pre>{JSON.stringify(node?.data?.filter?.where, null, 4)}</pre> : "(field1 >= 01)"}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}