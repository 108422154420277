import {Input, Stack, Typography} from "@mui/material";
import React from "react";


export const SqlFromInput = ({value, onChange}) => {

  return (
    <Stack direction={"row"} gap={1} alignItems={"flex-end"}>
      <Typography sx={{flex: 1}} fontWeight={"bold"} className={"sql-color"}>FROM</Typography>
      <Input
        placeholder={'${global.[field ]}'}
        sx={{flex: 3}}
        name={"from"}
        value={value}
        onBlur={onChange}
      />
    </Stack>

  )
}