import React, {useEffect, useMemo, useState} from "react";
import Modal from "@mui/material/Modal";
import {Button, IconButton, MenuItem, Select, Stack, Typography} from "@mui/material";
import {IconX} from "@tabler/icons-react";
import {grey, lightBlue} from "@mui/material/colors";
import {SqlSelectInput} from "./SqlSelectInput";
import {SqlFromInput} from "./SqlFromInput";
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import {QueryBuilder} from "react-querybuilder";
import { formatQuery } from 'react-querybuilder';
import {toast} from "react-toastify";
import {parseSQL} from "react-querybuilder/parseSQL";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "280px",
  maxWidth: "sm",
  width: "90%",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  p: 3,
  overflowY: "auto"
};

const initialQuery = { combinator: 'and', rules: [] };

export const SqlFilterModal = ({open, handleClose, node, project, onUpdate}) => {
  const [select, setSelect] = useState("")
  const [from, setFrom] = useState("")
  const [query, setQuery] = useState({...initialQuery});
  const [collection, setCollection] = useState(null);

  useEffect(() => {
    if (open) {
      setSelect(node?.data?.filter?.select || "");
      setFrom(node?.data?.filter?.from || "");
      setCollection(node?.data?.filter?.collection?.id ? node?.data?.filter?.collection : null);
      if (node?.data?.filter?.where?.sql) {
        const query = parseSQL(node?.data?.filter?.where?.sql, {
          params: [...node?.data?.filter?.where?.params]
        });
        setQuery(query);
      }
    } else {
      setSelect("")
      setFrom("")
      setQuery({...initialQuery})
      setCollection(null)
    }
  }, [open, node])

  useEffect(() => {
    console.log("QUERY",query);
  }, [query]);


  const onSave = () => {
    if (select && from && query && collection) {
      const where = formatQuery(query, { format: 'parameterized', parseNumbers: true });
      const filter = {
        select: select,
        from: from,
        where: where,
        collection: collection
      }
      const newNode = {...node};
      newNode.data.filter = {...filter};
      onUpdate(newNode);
      handleClose();
    } else {
      toast.error("Please enter sql query correct")
    }
  }

  const fields = useMemo(() => {
    return collection?.fields?.map(i => ({...i, label: i?.name})) || []
  }, [collection?.fields])

  const selectedCollection = useMemo(() => {
    return collection ? collection?.id || null : null;
  }, [collection])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack direction={"column"} gap={2} sx={style}>
        <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h4"} fontWeight={600}>SQL Filter</Typography>
          <IconButton onClick={handleClose}>
            <IconX/>
          </IconButton>
        </Stack>
        <Stack direction={"column"} gap={2}
               sx={{
                 position: "relative",
                 borderRadius: 1,
                 border: 1,
                 borderColor: "divider",
                 bgcolor: grey[50],
                 p: 1,
                 pb: 2,
               }}>
          <Stack direction={"column"} gap={1}>
            <Typography variant={"h5"} color={grey[500]}>Collection:</Typography>
            <Select
              size={"small"}
              value={selectedCollection}
              sx={{maxWidth: "200px"}}
              onChange={(e, newValue) => {
                const collection = project?.collections?.find(i => i?.id === e?.target?.value);
                setCollection(collection || null);
              }}
            >
              {project?.collections?.map(i => {
                return (
                  <MenuItem key={i?.id} value={i?.id}>{i?.name}</MenuItem>
                )
              })}
            </Select>
          </Stack>
          <SqlSelectInput value={select} onChange={e => setSelect(e?.target?.value)}/>
          <SqlFromInput value={from} onChange={e => setFrom(e?.target?.value)}/>
          {fields?.length > 0 ? <Stack direction={"column"} gap={1}>
            <Typography fontWeight={"bold"} className={"sql-color"}>WHERE</Typography>
            <QueryBuilderMaterial>
              <QueryBuilder fields={fields}
                            query={query} onQueryChange={setQuery}
              />
            </QueryBuilderMaterial>
          </Stack> : null}
        </Stack>
        <Stack direction={"row"} gap={1} justifyContent={"flex-end"} alignItems={"center"}>
          <Button onClick={handleClose} sx={{color: lightBlue[700]}}>Close</Button>
          <Button onClick={onSave} sx={{color: lightBlue[700]}}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}