import React, {useEffect, useState} from "react";
import {
  Fade,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {NodeType} from "../../../../model/ModelData";
import {editMenuItems, menuItemsInfo} from "./editMenuItems";
import {IconMenu, IconTrash} from "@tabler/icons-react";
import useConfirm from "../../../../hooks/useConfirm";
import {GreetMessageTab} from "./greet-messages/GreetMessageTab";
import {SettingsTab} from "./settings/SettingsTab";
import {StaticRoutesTab} from "./routes/StaticRoutesTab";
import {DynamicRoutesTab} from "./routes/DynamicRoutesTab";
import {KnowledgeBaseTab} from "./knowledge-base/KnowledgeBaseTab";
import {VarsTab} from "./vars/VarsTab";
import {PleaseWaitMessagesTab} from "./please-wait-messages/PleaseWaitMessagesTab";
import {SlotsTab} from "./slots/SlotsTab";
import {OutputParserTab} from "./output-parser/OutputParserTab";
import {LLMBoostTab} from "./llm-booster/LLMBoostTab";
import {getRoutes} from "../../../../utils/nodeMapper";
import {FilterTab} from "./filter/FilterTab";

export const EditNodeMenu = ({
                               open,
                               selectedNode,
                               selectedRoute,
                               nodes,
                               onClose,
                               onUpdate,
                               onRemove,
                               project,
                               flow,
                               showHideMenuButton = true
                             }) => {
  const [node, setNode] = useState(selectedNode);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (selectedNode) {
      let newItems = editMenuItems[selectedNode.type] || [];

      setItems(newItems);
      if (selectedNode?.id !== node?.id) {
        setValue("");
      }
    }
    setNode(selectedNode || null)
  }, [selectedNode])

  useEffect(() => {
    if (selectedRoute) {
      const routes = getRoutes(node?.routes);
      const route = routes?.find(r => r?.id === selectedRoute);
      if (route) {
        setValue(route?.variant)
      }
    }
  }, [selectedRoute, node])

  const onChangeData = (key, value) => {
    setNode(prev => {
      const state = {...node};
      state.data[key] = value;

      return state;
    })
  }

  return (
    open && node &&
    <>
      <Stack
        direction={"row"}
        sx={{minWidth: "280px", maxWidth: "280px", height: "100%", maxHeight: "100%"}}
      >
        <MenuItems node={node} setNode={setNode} onUpdate={onUpdate} value={value} setValue={setValue} items={items}
                   showHideMenuButton={showHideMenuButton}
                   onRemove={onRemove} onClose={onClose}/>

      </Stack>
      {
        value &&
        <Fade
          in={!!value}
          timeout={500}
        >
          <Stack direction={"column"} gap={2} sx={{
            p: 1,
            minWidth: "280px",
            maxWidth: "280px",
            maxHeight: "100%",
            borderRight: 1,
            overflowY: "auto",
            borderColor: value ? "divider" : "transparent"
          }}>
            {
              {
                "vars": <VarsTab node={node} setNode={setNode} project={project} onUpdate={onUpdate}/>,
                "greet": <GreetMessageTab node={node} setNode={setNode} onUpdate={onUpdate}/>,
                "please_wait_messages": <PleaseWaitMessagesTab node={node} setNode={setNode} onUpdate={onUpdate}/>,
                "slots": <SlotsTab node={node} setNode={setNode} onUpdate={onUpdate} project={project} onChangeData={onChangeData} />,
                "settings": <SettingsTab node={node} setNode={setNode} onUpdate={onUpdate} nodes={nodes} project={project}/>,
                "knowledge_base": <KnowledgeBaseTab node={node} setNode={setNode} onUpdate={onUpdate} project={project}/>,
                "output_parser": <OutputParserTab node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project}/>,
                "llm_boost": <LLMBoostTab node={node} setNode={setNode} onUpdate={onUpdate} onChangeData={onChangeData} project={project}/>,
                "static": <StaticRoutesTab node={node} setNode={setNode} nodes={nodes} onUpdate={onUpdate}
                                           flow={flow} project={project} selectedRoute={selectedRoute}/>,
                "dynamic": <DynamicRoutesTab node={node} setNode={setNode} nodes={nodes} onUpdate={onUpdate}
                                             project={project} flow={flow} selectedRoute={selectedRoute}/>,
                "filter": <FilterTab node={node} setNode={setNode} nodes={nodes} onUpdate={onUpdate}
                                             project={project} flow={flow} selectedRoute={selectedRoute}/>,
              }[value]
            }
          </Stack>
        </Fade>
      }
    </>

  )
}

const MenuItems = ({node, setNode, onUpdate, value, setValue, items, onRemove, onClose, showHideMenuButton}) => {
  const [Dialog, confirmDelete] = useConfirm(
    'Are you sure you want to delete this node?',
    node?.name || ""
  );

  const onDelete = async () => {
    const response = await confirmDelete();
    if (response) {
      onRemove(node?.id);
      onClose();
    }
  }

  return (
    <Stack direction={"column"} gap={2}
           sx={{p: 1, width: "100%", borderRight: 1, borderColor: value ? "divider" : "transparent"}}>
      <Dialog/>
      <Stack direction={"row"} gap={1} alignItems={"center"} sx={{width: "100%"}}>
        {
          showHideMenuButton &&
          <IconButton size={"small"} onClick={onClose}>
            <IconMenu size={17}/>
          </IconButton>
        }
        <Typography textTransform={"capitalize"} fontWeight={700} sx={{flex: 1}}>
          {node?.type?.replaceAll("_", " ")}
        </Typography>
        <IconButton size={"small"}
                    color={"error"}
                    onClick={onDelete}
                    disabled={node?.type === NodeType.START || node?.type === NodeType.START_FORM || node?.type === NodeType.END_FORM}
        >
          <IconTrash size={17}/>
        </IconButton>
      </Stack>
      <TextField
        size={"small"}
        value={node?.name}
        label={"Name"}
        onChange={(e) => setNode(prev => {
          const state = {...node};
          state.name = e.target.value;
          return state;
        })}
        onBlur={() => onUpdate(node)}
      />
      <List sx={{my: 0, py: 0}}>
        {
          items?.map(item => {
            const {primary, secondary} = menuItemsInfo[item];
            const isSelected = value === item;
            if (!node?.data?.use_llm && item === "llm_boost") {
              return null;
            }
            return (
              <ListItemButton
                onClick={() => setValue(isSelected ? "" : item)}
                sx={{
                  p: 0.5,
                  bgcolor: isSelected ? "rgba(220,228,234, 0.5)" : "transparent",
                  "&:hover": {bgcolor: "rgba(220,228,234, 0.5)"}
                }}
              >
                <ListItemText primary={primary} secondary={secondary}
                              primaryTypographyProps={{fontWeight: 700, fontSize: 13, textTransform: "capitalize"}}
                              secondaryTypographyProps={{fontSize: 11}}/>
              </ListItemButton>
            )
          })
        }

      </List>
    </Stack>
  )
}