import {v4 as uuidv4} from 'uuid';
import {
    IconKeyboard,
    IconPlayerPlay, IconTerminal, IconTopologyStar,
    IconVariable, IconChalkboard, IconBrandJavascript, IconWriting, IconArrowsSplit2
} from "@tabler/icons-react";
import {IconHook} from "../assets/icons/IconHook";
import {IconQuery} from "../assets/icons/IconQuery";
import {IconSwitch} from "../assets/icons/IconSwitch";
import {IconExit} from "../assets/icons/IconExit";
import {IconText} from "../assets/icons/IconText";
import {IconQA} from "../assets/icons/IconQA";

export const UserDetailsEntity = {
    id: uuidv4(),
    displayName: "",
    email: "",
    authUid: "",
    photoURL: "",
}

export const IntentType = {
    GLOBAL: "global",
    LOCAL: "local",
}

export const KB_TYPE = {
    URL: "URL",
    API: "API"
}

export const IntentEntity = {
    id: uuidv4(),
    name: "intent-name",
    type: "GLOBAL", // Global/Local
    flow: "", //flow id
    entries: [
        "Здраво како си",
        "Zdravo kako si",
        "Zdravo jas sum super a ti?"
    ]
}

export const ProjectEntity = {
    id: uuidv4(),
    name: "My first project",
    description: "This project is for A1 MK",
    entryNode: "", // Start node
    createdAt: new Date().getTime(),
    intents: [
        {
            id: uuidv4(),
            name: "yes_intent",
            description: "The user confirms the agent action.",
            values: [
                "Да",
                "Да Да",
                "Да така е.",
                "Така е.",
                "Во право си.",
                "Тој е",
                "Точно.",
                "Браво",
                "Аха",
                "Мислам да"
            ]
        },
        {
            id: uuidv4(),
            name: "no_intent",
            description: "The user declines the agent action.",
            values: [
                "Не",
                "Па не",
                "Не баш",
                "Мислам не",
                "Не е така.",
                "Не си во право",
                "Грешка си.",
                "Не грешка"
            ]
        }
    ],
    flows: [],
    entities: [],
    settings: {
        kb_api: "https://api.kb.openbrain.io",
        kb_method: "GET",
        qa_api: "https://qa-mk.openbrain.io",
        qa_method: "POST"
    }
}

export const FlowEntity = {
    id: uuidv4(),
    name: "Home",
    nodes: []
}

export const NodeType = {
    START: "start",
    QA: "qa",
    RECOMMENDATION: "recommendation",
    TEXT: "text",
    QUERY: "query",
    END: "end",
    START_FORM: "start_form",
    END_FORM: "end_form",
    INPUT: "input",
    CHOICE: "choice",
    START_FLOW: "start_flow",
    END_FLOW: "end_flow",
    SET_VAR: "set_var",
    JAVASCRIPT: "javascript",
    HOOK: "hook",
    SWITCH: "switch",
    TEACHING: "teach",
}

export const NodeTypeIcon = {
    start: IconPlayerPlay,
    qa: IconQA,
    text: IconText,
    query: IconQuery,
    end: IconExit,
    start_form: IconPlayerPlay,
    recommendation: IconTerminal,
    end_form: IconExit,
    input: IconWriting,
    choice: IconArrowsSplit2,
    start_flow: IconTopologyStar,
    end_flow: IconExit,
    set_var: IconVariable,
    hook: IconHook,
    switch: IconSwitch,
    teach: IconChalkboard,
    javascript: IconBrandJavascript,
}

export const ScopeType = {
    FLOW: "flow",
    GLOBAL: "global",
    FORM: "form",
}

export const TargetType = {
    FLOW: "flow",
    GLOBAL: "global",
    FORM: "form",
}

export const NodeEntity = {
    id: "",
    name: "",
    type: "", // NodeType
    coords: { x: 0, y: 0 },
    parameters: [],
    data: {
        greet: []
    },
    routes: {
        static: {
            next: {
                id: uuidv4(),
                target: null,
                name: "",
                type: "next",
                backstack: true,
                data: {
                    greet: ""
                }
            },
            no_match: {
                id: uuidv4(),
                target: null,
                name: "",
                type: "no_match",
                backstack: true,
                data: {
                    greet: ""
                }
            },
            fail: {
                id: uuidv4(),
                target: null,
                name: "",
                type: "fail",
                backstack: true,
                data: {
                    greet: ""
                }
            },
        },
        dynamic: []
    }
}


export const HookNodeEntity = {
    id: "init-node",
    name: "init-node-1",
    type: "hook",
    data: {
        api_url: "https://api.kb.openbrain.io/api/user/active",
        api_method: "GET",
        target: "global",
        params: [
            {
                source: "organization_id",
                scope: "global"
            }
        ]
    },
    routes: {
        static:  {
            next: {
                route_id: "route-1",
                type: "next",
                target: "choice-page-1",
                data: {
                    greet: ""
                }
            }
        }
    }
}