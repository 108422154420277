import React, {useState} from "react";
import {
  Box,
  Button,
  FormControl, IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table, TableBody, TableCell, TableHead, TableRow,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {IconPencil, IconPlus, IconTrash} from "@tabler/icons-react";
import {CreateVarModal} from "./CreateVarModal";

export const VarsTab = ({node, setNode, nodes, onUpdate, project}) => {
  const {primary, secondary} = menuItemsInfo.vars;
  const [open, setOpen] = useState(false);
  const [selectedVar, setSelectedVar] = useState(false);

  return (
    <>
      <CreateVarModal
        open={open}
        handleClose={() => {
          setOpen(false);
          setSelectedVar(null);
        }}
        selectedVar={selectedVar}
        node={node}
        project={project}
        onUpdateNode={onUpdate}
      />
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 600, fontSize: 13, py: 1, px: 0.5}}>Target</TableCell>
            <TableCell sx={{fontWeight: 600, fontSize: 13, py: 1, px: 0.5}}>From</TableCell>
            <TableCell sx={{fontWeight: 600, fontSize: 13, py: 1, px: 0.5}}>
              <Tooltip title={"Add new variable"}>
                <IconButton
                  variant={"outlined"}
                  size={"small"}
                  onClick={() => setOpen(true)}
                >
                  <IconPlus size={18} />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {node?.data?.params?.map((param, index) => {
            return (
              <TableRow key={`${param.source_scope}-${index}`}>
                <TableCell sx={{fontSize: 12, py: 1, px: 0.5}}>
                  ({param?.target_scope})  {param?.target}
                </TableCell>
                <TableCell sx={{fontSize: 12, py: 1, px: 0.5}}>
                  ({param?.source_scope})  {param?.source}
                </TableCell>
                <TableCell sx={{py: 1, px: 0.5}}>
                  <IconButton
                    variant={"outlined"}
                    size={"small"}
                    fullWidth
                    onClick={() => {
                      setSelectedVar(index);
                      setOpen(true)
                    }}
                  >
                    <IconPencil size={18}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

    </>
  )
}