import React, {useState} from "react";
import {NodeType} from "../../../../../model/ModelData";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {IconArrowsDiagonal} from "@tabler/icons-react";
import {Editor} from "@monaco-editor/react";
import {ParserModal} from "../../../../../components/modals/ParserModal";

export const OutputParserTab = ({node, setNode, nodes, onUpdate, project}) => {
  const [expandPersona, setExpandPersona] = useState(false);

  const {primary, hook, secondary} = menuItemsInfo.output_parser;

  const isHook = node?.type === NodeType.HOOK;

  const onChangeData = (key, value) => {
    setNode(prev => {
      const state = {...node};
      state.data[key] = value;
      onUpdate(state);
      return state;
    })
  }

  return (
    <>
      <ParserModal
        handleClose={() => setExpandPersona(false)}
        open={expandPersona}
        onSuccess={(content) => onChangeData(isHook ? "api_output_parser" : "parser", content)}
        text={isHook ? node?.data?.api_output_parser || "" : node?.data?.parser || ""}
      />
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{isHook ? hook : primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>
      <Box>
        <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pb: 0.5}}>
          <Typography fontWeight={700}>Javascript</Typography>
          <Box>
            <IconButton onClick={() => setExpandPersona(true)}>
              <IconArrowsDiagonal size={15}/>
            </IconButton>
          </Box>
        </Stack>
        <Editor
          height="200px"
          defaultLanguage="javascript"
          value={isHook ? node?.data?.api_output_parser || "" : node?.data?.parser || ""}
          // onChange={(value, e) => onChangeData(isHook ? "api_output_parser" : "parser", value)}
        />
      </Box>
    </>
  )
}