import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import {
  Button,
  FormControl, FormControlLabel, FormLabel,
  IconButton,
  InputLabel,
  MenuItem, Radio, RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {IconBed, IconX} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "280px",
  maxWidth: "sm",
  width: "90%",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  p: 3,
  overflowY: "auto"
};

const VarType = {
  CONST: "CONST",
  TRANSFER: "TRANSFER"
}

const initParam = {
  source_scope: "const",
  source: "",
  target_scope: "flow",
  scope: "flow",
  target: ""
}

//Selected var is index
export const CreateVarModal = ({open, handleClose, selectedVar, node, onUpdateNode, project}) => {
  const [type, setType] = useState(VarType.CONST);
  const [param, setParam] = useState({...initParam})

  useEffect(() => {
    if (!open) {
      setParam({...initParam})
    } else if (selectedVar >= 0) {
      const params = node?.data?.params || [];
      const selectedParam = params[selectedVar];
      console.log(node)
      console.log(selectedParam)
      console.log(selectedVar)
      if (selectedParam) {
        console.log(selectedParam);
        setType(selectedParam?.source_scope === "const" ? VarType.CONST : VarType.TRANSFER)
        setParam(selectedParam);
      }
    }
  }, [selectedVar, node, open])

  useEffect(() => {
    setParam(prev => ({...prev, source_scope: type === VarType.TRANSFER ? "flow" : "const"}))
  }, [type])


  const onSubmit = () => {
    const newNode = {...node};
    const params = newNode?.data?.params || [];
    if (selectedVar) {
      params[selectedVar] = {...param};
    } else {
      params.push({...param});
    }
    newNode.data.params = params;
    onUpdateNode(newNode);
    handleClose();
  }

  const onDelete = () => {
    const newNode = {...node}
    const params = newNode?.data?.params.filter((p, ind) => ind !== selectedVar);
    newNode.data.params = params;
    onUpdateNode(newNode);
    handleClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack direction={"column"} gap={2} sx={style}>
        <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant={"h4"} fontWeight={600}>{selectedVar ? "Edit" : "Create"} Var</Typography>
          <IconButton onClick={handleClose}>
            <IconX/>
          </IconButton>
        </Stack>
        <Typography>Target</Typography>
        <Stack direction={"row"} gap={1}>
          <ScopeSelect
            value={param?.target_scope}
            onChange={(e) => {
              setParam(prev => {
                const params = {...prev};
                params.target_scope = e.target.value
                params.scope = e.target.value
                return params;
              })
            }}
            key={"target_scope"}
            node={node}
          />
          <TextField
            size={"small"}
            label={"Target"}
            sx={{flex: 1}}
            value={param?.target || ""}
            onChange={(e) => {
              setParam(prev => {
                const params = {...prev};
                params.target = e.target.value
                return params;
              })
            }}
          />
        </Stack>
        <RadioGroup
          defaultValue="female"
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
          row
          value={type}
          onChange={(e, newValue) => setType(newValue)}
        >
          <FormControlLabel value={VarType.CONST} control={<Radio />} label={<><Typography fontWeight={600}>Constant</Typography><Typography variant={"body2"} fontSize={11} color={grey[600]}>Set Variable from constants</Typography></>} />
          <FormControlLabel value={VarType.TRANSFER} control={<Radio />} label={<><Typography fontWeight={600}>Transfer</Typography><Typography variant={"body2"} fontSize={11} color={grey[600]}>Transfer variable from one scope to another</Typography></>} />
        </RadioGroup>
        {
          type === VarType.TRANSFER ?
            <Stack direction={"row"} gap={1}>
              <ScopeSelect
                value={param?.source_scope}
                onChange={(e) => {
                  setParam(prev => {
                    const params = {...prev};
                    params.source_scope = e.target.value
                    return params;
                  })
                }}
                key={"source_scope"}
                node={node}
              />
              <TextField
                size={"small"}
                label={"Source"}
                sx={{flex: 1}}
                value={param?.source || ""}
                onChange={(e) => {
                  setParam(prev => {
                    const params = {...prev};
                    params.source = e.target.value
                    return params;
                  })
                }}
              />
            </Stack>
            : <FormControl size={"small"} sx={{flex: 1}}>
              <InputLabel id={`constant-label`} sx={{textTransform: "capitalize"}}>Constants</InputLabel>
              <Select
                labelId={`constant-label`}
                size={"small"}
                label={"Constants"}
                value={param?.source}
                onChange={(e) => {
                  setParam(prev => {
                    const params = {...prev};
                    params.source = e.target.value
                    return params;
                  })
                }}
              >
                {
                  project?.constants &&
                  Object?.entries(project?.constants)?.map(([key, value]) => {
                    return <MenuItem key={key} value={key}>{key} - {value}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
        }

        <Stack direction={"row"} gap={1} justifyContent={"space-between"}>
          <Button
            variant={"contained"}
            color={"secondary"}
            sx={{borderRadius: 0, textTransform: "capitalize"}}
            disabled={!param?.source || !param?.target}
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button
            variant={"outlined"}
            color={"secondary"}
            sx={{borderRadius: 0, textTransform: "capitalize"}}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {
            selectedVar >= 0 && <Button
              variant={"outlined"}
              color={"error"}
              sx={{borderRadius: 0, textTransform: "capitalize", ml: "auto"}}
              onClick={onDelete}
            >
              Delete
            </Button>
          }
        </Stack>
      </Stack>
    </Modal>
  )
}

const ScopeSelect = ({value, onChange, node, key}) => {

  return (
    <FormControl size={"small"} sx={{flex: 1}}>
      <InputLabel id={`${key}-target-label`} sx={{textTransform: "capitalize"}}>Scope</InputLabel>
      <Select
        labelId={`${key}-target-label`}
        size={"small"}
        label={"Scope"}
        value={value}
        onChange={onChange}
      >
        {node?.data?.form_id && <MenuItem value={"form"}>Form</MenuItem>}
        <MenuItem value={"flow"}>Flow</MenuItem>
        <MenuItem value={"global"}>Global</MenuItem>
      </Select>
    </FormControl>
  )
}