import React, {useEffect, useState} from "react";
import {Button, Fade, IconButton, Stack, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  IconTrash,
  IconX
} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {toast} from "react-toastify";
import {IntentEntity} from "../../../../model/ModelData";
import {v4 as uuidv4} from "uuid";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "280px",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: 0,
  border: '0px solid #000',
  boxShadow: 24,
  px: 3,
  py: 3,
  overflowY: "auto"
};

export const CreateIntentModal = ({open, handleClose, intent, handleSubmit, onRemove, flow, project}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    if (intent) {
      setIsGlobal(intent?.type === "GLOBAL");
      setName(intent?.name);
      setDescription(intent?.description || "");
      setEntries(intent?.entries);
    }
  }, [intent]);
  const onCreate = async () => {
    if (!name || entries?.length === 0) {
      toast.error("All fields are required!");
      return;
    }
    let newIntent = {
      ...IntentEntity,
      id: intent?.id || uuidv4(),
      name: name,
      description: description,
      type: isGlobal ? "GLOBAL" : "LOCAL", // Global/Local
      flow: intent?.flow || flow?.id, //flow id
      entries: entries
    }

    handleSubmit(newIntent);
    onClose();
  }

  const onClose = () => {
    setName("");
    setEntries([]);
    setIsGlobal(false);
    setDescription("");
    handleClose();
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={onClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>

          <Stack direction={"column"} gap={2} sx={style}>
            <Typography variant={"h4"} fontWeight={"bold"} sx={{mb: 1}}>Create new intent</Typography>
            <TextField
              size={"small"}
              label={"Intent name"}
              value={name}
              error={name !== "" && project?.intents?.filter(i => i.name === name && intent?.id !== i.id).length > 0}
              helperText={
                name !== "" && project?.intents?.filter(i => i.name === name && intent?.id !== i.id).length > 0 ?
                  "This name already exists."
                  : "Intent name must be in lowercase and without spaces (_)"
              }
              onChange={e => setName(e.target.value?.replaceAll(" ", "_").toLowerCase())}
            />
             <TextField
              size={"small"}
              label={"Intent description"}
              multiline
              rows={2}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            {
              entries?.map((entry, index) => {
                return (
                  <Stack key={index} direction={"row"} alignItems={"center"} gap={1}
                         sx={{width: "100%"}}>

                    <TextField
                      size={"small"}
                      label={`Entry [${index}]`}
                      value={entry}
                      sx={{flex: 3}}
                      onChange={e => setEntries(prev => {
                        const state = [...prev];
                        state[index] = e.target.value;
                        return state;
                      })}
                    />
                    <Box>
                      <IconButton color={"error"} size={"small"} onClick={() => {
                        const items = entries?.filter((i, ind) => index !== ind);
                        setEntries(items);
                      }}>
                        <IconTrash size={16}/>
                      </IconButton>
                    </Box>
                  </Stack>
                )
              })
            }
            <Button
              size={"small"}
              onClick={() => setEntries([...entries, ""])}
              sx={{fontSize: 13, textTransform: "none"}}
            >
              Add new entry
            </Button>

            <Stack direction={'row'} gap={1} useFlexGap flexWrap={"wrap"}>
              <Button
                variant={"contained"}
                color={"secondary"}
                sx={{borderRadius: 0, textTransform: "capitalize"}}
                disabled={
                  !name ||
                  entries?.length === 0 ||
                  name !== "" && project?.intents?.filter(i => i.name === name && intent?.id !== i.id).length > 0
                }
                onClick={onCreate}
              >
                Save
              </Button>
              <Button
                variant={"outlined"}
                color={"secondary"}
                sx={{borderRadius: 0, textTransform: "capitalize"}}
                onClick={onClose}
              >
                Cancel
              </Button>
              {
                intent?.id &&
                <Button
                  variant={"outlined"}
                  color={"error"}
                  disabled={intent?.name === "yes_intent" || intent?.name === "no_intent"}
                  sx={{borderRadius: 0, textTransform: "capitalize", ml: "auto"}}
                  onClick={() => {
                    onRemove(intent?.id)
                    onClose();
                  }}
                >
                  <IconTrash size={20} />
                </Button>
              }

            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
