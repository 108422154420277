import React from "react";
import {Box, Button, IconButton, Stack, TextField, Typography} from "@mui/material";
import {menuItemsInfo} from "../editMenuItems";
import {IconX} from "@tabler/icons-react";

export const PleaseWaitMessagesTab = ({node, setNode, onUpdate}) => {

  const onChangeMessage = async (index, value) => {
    const state = {...node}
    if (state.data.please_wait_messages[index] !== value) {
      state.data.please_wait_messages[index] = value;
      setNode(state)
    }
  }
  const onUpdateMessage = async () => {
    await onUpdate(node);
  }

  const removeMessage = async (index) => {
    const state = {...node}
    state.data.please_wait_messages = state.data.please_wait_messages?.filter((msg, ind) => ind !== index);
    await onUpdate(state);
  }

  const addMessage = async () => {
    const state = {...node}
    if (state?.data?.please_wait_messages?.length === 0) {
      state.data.please_wait_messages = []
    }
    state.data.please_wait_messages = [...state.data.please_wait_messages, ""];

    await onUpdate(state);
  }

  const {primary, secondary} = menuItemsInfo.please_wait_messages;

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: "divider", pb: 0.5}}>
        <Typography fontWeight={700}>{primary}</Typography>
        <Typography variant={"subtitle2"} fontSize={13}>{secondary}</Typography>
      </Box>
      {
        node?.data?.please_wait_messages?.map((msg, index) => {
          return <Stack direction={"row"} gap={1}>
            <TextField
              value={msg}
              size={"small"}
              placeholder={"Write here message..."}
              sx={{flex: 1}}
              onChange={e => onChangeMessage(index, e.target.value)}
              onBlur={e => onUpdateMessage(index, e.target.value)}
            />
            <IconButton
              onClick={() => removeMessage(index)}
            >
              <IconX size={13} />
            </IconButton>
          </Stack>
        })
      }

      <Button
        variant={"outlined"}
        color={"secondary"}
        onClick={addMessage}
      >
        Add new message
      </Button>
    </>
  )
}